/*global color change*/
:root{
    --main-zapp: #013939;         /* var(--main-zapp) */
    --main-light-zapp: #FFC542;   /* var(--main-light-zapp) */
    --main-bland-zapp: #DBF7F4;      /* var(--main-bland-zapp) */
    --main-font-zapp: #171725;    /* var(--main-font-zapp) */
    --orange-lg-font-zapp: #FFFBF1;    /* var(--orange-lg-font-zapp) */
    --light-blue-zapp: #50B5FF;   /* var(--light-blue-zapp) */
    --bright-green-zapp: #DBF7F4; /* var(--bright-green-zapp) */
    --brown-zapp: #D9D9D9;        /* var(--brown-zapp) */
    --light-brown-zapp: #F5F4FF;  /* var(--light-brown-zapp) */
    --bg-search-zapp: #FAFAFB;  /* var(--bg-search-zapp) */
    --cl-bdd: #92929D;            /* var(--cl-bdd) */
    --cl-bd: #E2E2EA;             /* var(--cl-bd) */
    --cl-placeholder: #E2E2EA;   /* var(--cl-placeholder) */
    --btn-cancel: #F1F1F5;   /* var(--btn-cancel) */
    --cl-icon-hd: #645A3D;   /* var(--cl-icon-hd) */
    --cl-hover: #013939;     /* var(--cl-hover) */
    --bg-brown: #F2F2F2;     /* var(--bg-brown) */
    --main-font : "Josefin Sans";   /* var(--main-font) */
    --main-font-2 : "Work Sans";   /* var(--main-font-2) */
}