.qColorActive {
    color: var(--main-zapp);
}

.qIcon i {
    opacity: 0;
    margin-left: 4px;
}

.qIcon:hover {
    color: var(--main-zapp);
}

.qIcon:hover i{
    opacity: 1;
}

.qIconActive:hover{
    color: var(--main-zapp);
}
