@font-face {
    font-family: Josefin Sans;
    src:url(../css/font/josefin-sans/JosefinSans-Regular.eot);
    src: url(../css/font/josefin-sans/JosefinSans-Regular.woff) format("woff"),
    url(../css/font/josefin-sans/JosefinSans-Regular.svg) format("svg");
}
@font-face {
    font-family: Work Sans;
    src:url(../css/font/Work-Sans/WorkSans-Regular.eot);
    src: url(../css/font/Work-Sans/WorkSans-Regular.woff) format("woff"),
    url(../css/font/Work-Sans/WorkSans-Regular.svg) format("svg");
}

/*@font-face {*/
/*    font-family: Poppins;*/
/*    src:url(../css/font/Poppins-Regular/Poppins-Regular.eot);*/
/*    src: url(../css/font/Poppins-Regular/Poppins-Regular.woff) format("woff"),*/
/*    url(../css/font/Poppins-Regular/Poppins-Regular.svg) format("svg");*/
/*}*/