/*top page*/
.header-detail,
.navbar-header{
    height: 48px;
}
[data-layout=horizontal] .navbar-menu {
    margin-top: 48px;
}
[data-layout=horizontal] .menu-dropdown{
    min-width: auto;
    /*width: 90%;*/
    border-radius: unset;
}
.bg-header:before{
    content: "";
    position: absolute;
    background: var(--main-bland-zapp);
    z-index: 0;
    width: 100%;
    height: 100%;
}
.bg-header:after{
    content: "";
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
}
.header-detail{
    background: var(--main-bland-zapp);
}
.topbar-user{
    background-color: transparent;
}
/*logo*/
[data-layout=horizontal] .horizontal-logo {
    padding-left: 0;
}
/*.navbar-header{*/
/*    padding-right: 0;*/
/*}*/
/*menu*/
#navbar-nav{
    padding: 6px 0;
    position: relative;
    height: 40px;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link{
    padding: 5px 25px;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-sm .nav-link{
    padding: 7px 25px !important;
    height: 37px;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link:first-child{
    padding-left: 0px;
}
#navbar-nav .nav-link .menu-link.menu-link-ams{
    padding: 0;
    padding-left: 20px;
}
.navbar-menu .navbar-nav .nav-link.menu-link-ams.active{
    background: var(--cl-hover);
    border-radius: 10px;
    padding: 0.75rem 10px;
    color: #FFFBF1;
    margin-right: 20px;
    height: 27px;
}
[data-layout=horizontal] .navbar-menu .navbar-nav>.nav-item>.nav-link[data-bs-toggle=collapse]:after{
    display: none;
}

[data-layout=horizontal] .navbar-menu{
    box-shadow: inset 0px -1px 0px var(--cl-bd);
}
.nav-ams{
    float: right;
}

