.popupCopied {
    bottom: 100%;
    top: auto;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
     /*visibility: hidden;*/
    z-index: 50;
     opacity: 1;
    position: absolute;
    right: 0;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: aliceblue;
    padding: 10px;
}
